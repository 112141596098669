import { render, staticRenderFns } from "./discord.vue?vue&type=template&id=082e884b&"
import script from "./discord.vue?vue&type=script&lang=js&"
export * from "./discord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports